<template>
    <div class="attribution-picker">
        <attribution-field
            v-model="websiteAttribution"
            :windows="{
                view: ['1d', '7d', '28d'],
                click: ['1d', '7d', '28d']
            }"
            class="attribution-picker-field"
            label="WEBSITE ATTRIBUTION" />
        <attribution-field
            v-model="offlineAttribution"
            :windows="{
                view: ['1d', '7d', '28d'],
                click: ['1d', '7d', '28d']
            }"
            class="attribution-picker-field"
            label="OFFLINE ATTRIBUTION" />
    </div>
</template>

<script>
import AttributionField from '@/components/globals/fields/AttributionField';
import { UPDATE_FB_ATTRIBUTION } from '@/store/mutation-types';
import { mapGetters } from 'vuex';

export default {
    components: {
        AttributionField
    },
    computed: {
        ...mapGetters([
            'userIsClient'
        ]),
        websiteAttribution: {
            get() {
                return this.$store.state.metrics.facebook.ads.attribution.website;
            },
            set(data) {
                this.$store.commit(UPDATE_FB_ATTRIBUTION, { website: data });
            }
        },
        offlineAttribution: {
            get() {
                return this.$store.state.metrics.facebook.ads.attribution.offline;
            },
            set(data) {
                this.$store.commit(UPDATE_FB_ATTRIBUTION, { offline: data });
            }
        },
    }
};
</script>

<style lang="scss" scoped>
.attribution-picker {
    @media (min-width: $bp-lg) {
        display: flex;
        align-items: center;
    }
    .attribution-picker-field {
        margin: 0 0 20px 0;
        @media (min-width: $bp-lg) {
            margin: 0 8px 0 0;
        }
        &:last-child {
            margin: 0;
        }
    }

}
</style>
